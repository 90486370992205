.wrapper {
   position:relative;
  }
  
  .player {
    position:relative;
    width: 100%;
    height: 100%;
  }

  
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  .loader {
    width:30px;
    height:30px;
    margin: 40px auto;
    border-radius: 50%;
    background: #2f2e41;
    }